<template>
  <Header/>
  <router-view/>
  <Footer/>
</template>

<script>
import Header from "@/components/HeaderComponent";
import Footer from "@/components/FooterComponent";
export default {
  components: {Footer, Header}
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
