<template>
  <header>

    <nav class="custom-container">
<!--      <router-link to="/"  class="logo"><img class="logo-image" :src="require('@/assets/logo.svg')" alt="Logo"></router-link>-->
      <router-link to="/"  class="logo"><img class="logo-image" src="../assets/images/logo.svg" width="240" height="52" alt="Logo"></router-link>

      <div class="nav-links d-none d-lg-flex">
        <router-link to="/about" class="nav-link" :class="{white_link: whiteLink}">About API</router-link>
        <router-link to="/contacts" class="nav-link" :class="{white_link: whiteLink}">Contacts</router-link>
        <router-link to="/documentation" class="nav-link" :class="{white_link: whiteLink}">Documentation</router-link>
        <router-link to="/pricing" class="nav-link" :class="{white_link: whiteLink}">Pricing</router-link>
      </div>

      <router-link to="/contacts/" class="btn get_started_btn d-none d-lg-flex" :class="{hidden: currentRoute == 'contacts'}" >Get Started For Free</router-link>

      <button class="nav_trigger d-lg-none" @click="sideNavisOpen=!sideNavisOpen" name="mobile menu">
        <svg width="35" height="17" viewBox="0 0 35 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="35" height="2.00542" fill="white"/>
          <rect y="7.02783" width="35" height="2.00542" fill="white"/>
          <rect y="14.0557" width="35" height="2.00542" fill="white"/>
        </svg>

<!--        <svg id="trigger_background"  width="108" height="90" viewBox="0 0 108 90" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path-->
<!--              d="M396.671 -99.0822C540.172 -99.0824 417.24 -87.4018 449.438 -85.0264C457.851 -66.2863 472.935 -20.4127 465.973 13.1612C458.556 48.9294 442.921 63.9909 423.918 71.5817C483.243 71.8887 626.674 74.9242 455.681 74.9242H416.523C416.163 77.3525 397.086 79.4193 413.759 74.9242H202.866C133.193 74.9242 131.129 100.488 63.9223 83.6345C0.854515 67.8192 -24.5287 -10.7203 29.4262 -39.2264C83.381 -67.7325 33.9047 -62.805 78.3508 -99.0824C152.553 -159.647 339.939 -99.0822 396.671 -99.0822Z"-->
<!--              fill="#19335A"/>-->
<!--        </svg>-->
        <svg  id="trigger_background"  width="108" height="90" viewBox="0 0 108 90" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M62.7897 83.6345C18.471 72.5209 -3.13386 33.5 0.366935 0H108V89.8637C93.6947 89.5251 79.8286 87.9072 62.7897 83.6345Z"
              fill="#19335A"/>
        </svg>


      </button>

    </nav>

    <teleport to="body">
      <transition name="side-nav">
      <div class="side-nav" v-if="sideNavisOpen && window.width <= 992">
        <div class="side-nav__wrapper">
          <div class="side-nav__header">
            <button class="nav-close_trigger" @click="sideNavisOpen=!sideNavisOpen">
              <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="34.8885" height="1.99903" transform="matrix(0.70484 0.709367 -0.70484 0.709367 1.40918 0)"
                      fill="white"/>
                <rect width="34.8885" height="1.99903" transform="matrix(-0.70484 0.709367 0.70484 0.709367 24.5908 0)"
                      fill="white"/>
              </svg>

            </button>
          </div>

          <div class="side-nav__body">
<!--            <svg class="side-nav__body__background" width="251" height="618" viewBox="0 0 251 618" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path-->
<!--                  d="M3.11949 -397.319C-26.1025 -766.306 157.967 -661.873 221.955 -681.318C307.272 -669.075 645.072 -651.934 819.825 -681.318C1038.27 -718.049 1332.71 -712.678 1643.83 -660.822C1954.94 -608.966 2524.64 -317.757 2541.08 -74.5994C2557.51 168.559 2586.31 566.089 2551.13 584.678C2500.35 611.512 1226.12 500.113 386.333 617.642C12.7618 618.62 8.4359 320.268 3.1199 177.613C-6.0221 -67.6916 16.9875 -222.209 3.11949 -397.319Z"-->

<!--                  fill="#19335A"/>-->
<!--            </svg>-->
            <svg class="side-nav__body__background" width="517" height="495" viewBox="0 0 517 495" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M23.4764 142.384C28.7886 256.744 33.1116 495.919 406.416 495.135H523L520.502 0H21.3584C20.548 43.5854 21.0533 90.2242 23.4764 142.384Z"
                  fill="#19335A"/>
            </svg>


            <div class="side-nav__links">
              <router-link to="/about" class="side-nav__link" >About API</router-link>
              <router-link to="/contacts" class="side-nav__link">Contacts</router-link>
              <router-link to="/documentation" class="side-nav__link">Documentation</router-link>
              <router-link to="/pricing" class="side-nav__link">Pricing</router-link>
            </div>

          </div>

        </div>
      </div>
      </transition>
    </teleport>

  </header>

</template>

<script>
export default {
  name: "HeaderComponent",
  computed: {
    whiteLink() {
      return this.$route.name == "Landing" || this.$route.name == "LandingQuote";
      // return false;
    },
    currentRoute(){
      return this.$route.name;
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  data(){
    return{
      sideNavisOpen:false,
      window: {
        width: 0,
        height: 0
      }
    }
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  },
  watch:{
    sideNavisOpen(){
      if(this.sideNavisOpen){
        document.querySelector("body").style.overflow="hidden";
      }else{
        document.querySelector("body").style.overflow="";
      }
    },
    $route(to, from) {
      if (this.sideNavisOpen)
        this.sideNavisOpen = false;
    }
  }
}
</script>

<style scoped lang="scss">

header{
  padding-top: 2.1875rem;
  position: relative;
  z-index: 1;
  @include media-breakpoint-down(lg){
      padding-top: 0.625rem;

  }
}

nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-image{
  width: 100%;
  max-width: 240px;
}
.nav-links{
  display: flex;
  gap: 42px;
  .nav-link {
    color: $links-color;
    font-size: 1rem;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
    &.router-link-exact-active{
      color: #858585;
    }

    &.white_link{
      color: $white-color;
    }
  }
  @include media-breakpoint-down(lg){
    gap: unset;
    justify-content: space-around;
    flex-grow: 1;
  }
  @include media-breakpoint-down(md){
    display: none;
  }
}

.get_started_btn {
  padding: 0 1.5625rem;
  height: 2.5rem;
  vertical-align: middle;
}

.nav_trigger{
  background: none;
  outline: none;
  border: none;
  //position: relative;

  #trigger_background{
    position: absolute;
    z-index: -1;
    top: -0.625rem;
    right: 0;
  }
}

.side-nav {
  //display: none;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  //background-color: transparent;
  //transition: background-color 0.2s ease-in-out;
  background: rgba(249, 250, 252, 0.9);
  &.open{
    display: block;

  }

  &__wrapper{
    position: relative;
    //width: 100%;
    height: 100%;
     width: 70%;
    margin-left: auto;
  }
  &__header{
    padding-top: 1.5625rem;
    display: flex;
    position: relative;
    z-index: 1;
  }
  &__body{
    //position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;

    &__background{
      position: absolute;
      top: 0;
      left: -15px;
      z-index: 0;
      //width: 100%;
      @include media-breakpoint-up(md){
        left: unset;
        right: 0;
      }
    }

  }
  &__links{
    margin-top: 90px;
    display: inline-flex;
    flex-direction: column;
    gap: 0.625rem;
    position: relative;
  }
  &__link{
    color: $white;
    &:hover{
      text-decoration: underline;
      color: $white;
    }
    &.router-link-exact-active{
      color: $gray-1-color;
    }
  }
  .nav-close_trigger{
    background: none;
    outline: none;
    border: none;
    margin-left: auto;
    margin-right: 1.25rem;
  }
}

.side-nav-enter-active {
  animation: background-color-in .5s;
}
.side-nav-leave-active {
  animation: background-color-in .5s reverse;
}

//.side-nav-enter-active .side-nav__body__background{
//  animation: morph-in .5s;
//}
//.side-nav-leave-active .side-nav__body__background{
//  animation: morph-out  .5s;
//}
//
.side-nav-enter-active .side-nav__body__background path{
  animation: morph-in 0.5s linear;
}
.side-nav-leave-active .side-nav__body__background path{
  animation: morph-out 0.5s linear;
}

.side-nav-enter-active .side-nav__links,
.side-nav-enter-active .nav-close_trigger{
  animation: opacity-in .5s cubic-bezier(.32,.53,.57,.21);
}

.side-nav-leave-active .side-nav__links,
.side-nav-leave-active .nav-close_trigger{
  animation: opacity-in  .5s reverse cubic-bezier(.32,.53,.57,.21);
}

@keyframes background-color-in {
  0% {
    background-color: transparent;
  }
  100% {
     background: rgba(249, 250, 252, 0.9);
  }
}

@keyframes opacity-in {
  0% {
    opacity: 0;
  }
  100% {
     opacity: 1;
  }
}

@keyframes morph-out{
  100% {
    transform: translate3d(38%,-84%,0);
  }
  0% {
    transform: translate3d(0,0,0);
  }
}


@keyframes morph-in{
  0% {
    d: path('M170.888 40.2592C172.793 72.5944 174.343 140.221 308.197 139.999H516.5V0H170.129C169.838 12.3238 170.019 25.5109 170.888 40.2592Z');
  }
 5% {
    d: path('M151.085 63.2644C153.413 114.077 155.308 220.347 318.907 219.999H516.5V0H150.157C149.802 19.366 150.023 40.0886 151.085 63.2644Z');
  }
  10%{
    d: path('M119.529 89.1453C122.81 160.745 125.479 310.49 356.006 309.999H598.5L523 0H118.221C117.721 27.2884 118.033 56.4885 119.529 89.1453Z');
  }
  15%{
    d: path('M74.9732 115.026C79.2061 207.413 82.6507 400.632 380.104 399.998H527.5L522 0H73.2856C72.6399 35.2108 73.0424 72.8883 74.9732 115.026Z');
  }
  20%{
    d: path('M-0.000730753 256C5.31148 371.945 33.1106 493.793 406.415 492.998H523L520.501 -9H21.3577C20.5473 35.1896 -2.42386 203.117 -0.000730753 256Z');
  }
  35%{
    d: path('M34.5 172C86.9001 397.001 193 465.5 466.999 495.135H523L520.501 0H21.358C20.5476 43.5854 17.3599 98.4019 34.5 172Z');
  }
  100% {
    d: path('M23.4764 142.384C28.7886 256.744 33.1116 495.919 406.416 495.135H523L520.502 0H21.3584C20.548 43.5854 21.0533 90.2242 23.4764 142.384Z');
  }
  //20%, 40%, 80%{
  //  d: path('M-0.000730753 256C5.31148 371.945 33.1106 493.793 406.415 492.998H523L520.501 -9H21.3577C20.5473 35.1896 -2.42386 203.117 -0.000730753 256Z');
  //}
  //25%, 70%, 95%{
  //  d: path('M34.5 172C86.9001 397.001 193 465.5 466.999 495.135H523L520.501 0H21.358C20.5476 43.5854 17.3599 98.4019 34.5 172Z');
  //}
  //30%, 55%, 75%, 90%,100% {
  //  d: path('M23.4764 142.384C28.7886 256.744 33.1116 495.919 406.416 495.135H523L520.502 0H21.3584C20.548 43.5854 21.0533 90.2242 23.4764 142.384Z');
  //}
}
//
//@keyframes morph-out{
//  100% {
//    d: path('M200.888 40.2592C202.793 72.5944 204.343 140.221 338.197 139.999H519V0H200.129C199.838 12.3238 200.019 25.5109 200.888 40.2592Z');
//  }
//  0% {
//    d: path('M23.4764 142.384C28.7886 256.744 33.1116 495.919 406.416 495.135H523L520.502 0H21.3584C20.548 43.5854 21.0533 90.2242 23.4764 142.384Z');
//  }
//}


</style>