<template>
  <footer>
    <div class="custom-container">
      <div class="row g-0 logo_container">
        <div class="col-12 col-md-6">
          <img class="logo" src="../assets/images/white_logo.svg" height="45" width="208" alt="charterApi logo">
        </div>
        <div class="col-12 col-md-6 d-none d-md-block">
          <div class="social_links">
            <a href="https://www.facebook.com/aviapages" class="social_link"><img height="44" width="44" src="../assets/images/facebook_icon.webp" alt="facebook icon"></a>
            <a href="https://www.linkedin.com/company/aviapages-com" class="social_link"><img height="44" width="44" src="../assets/images/linkedin_icon.webp" alt="linkedin icon"></a>
            <a href="https://www.youtube.com/channel/UCvlVm4usoe_WCa87SeiSBzg" class="social_link"><img height="44" width="44" src="../assets/images/youtube_icon.webp" alt="youtube icon"></a>
            <a href="https://twitter.com/aviapagescom" class="social_link"><img height="44" width="44" src="../assets/images/twitter_icon.webp" alt="twitter icon"></a>
            <a href="https://www.instagram.com/aviapagescom/" class="social_link"><img height="44" width="44" src="../assets/images/instagram_icon.webp" alt="instagram icon"></a>
            <a href="https://telegram.me/aviapages" class="social_link"><img height="44" width="44" src="../assets/images/telegram_icon.webp" alt="telegram icon"></a>
          </div>
        </div>
      </div>

      <div class="row footer_links">
        <div class=" col-12 col-md-4 flex-column">
          <a class="fs24 d-none d-md-flex" href="tel:+31 970 102 80 860">+31 970 102 80 860</a>
          <a class="fs24 d-none d-md-flex" href="mailto:sales@charterapi.com">sales@charterapi.com</a>
          <div class="signup_newsletter">
            <div class="signup_newsletter__label">Sign up to receive our newsletter</div>
            <div class="signup_newsletter__wrapper">
              <input type="text" class="signup_newsletter__input" v-model="subscription_email" placeholder="Email Address" @keyup.enter="sendMessage()" >

              <button class="signup_newsletter__submit_button" @click="sendMessage()">
                <img src="../assets/images/submit_newsletter_button.svg" height="14" width="8" alt="submit_button">
              </button>
              <div class="signup_newsletter__notification">
                {{ subscription_email_notification }}
              </div>
            </div>
          </div>
        </div>
        <div class="navigation_links col-12 col-md-3 ">
          <div class="navigation_links__wrapper ">
            <h4 class="api_heading">API</h4>
            <a href="/about" class="footer_nav_link">About API</a>
            <a href="/contacts" class="footer_nav_link">Contacts</a>
            <a href="https://aviapages.com/aviapages_api/" class="footer_nav_link">Documentation</a>
            <a href="/pricing" class="footer_nav_link">Pricing</a>
          </div>
        </div>

        <div class="description__wrapper col-12 col-md-5 d-flex flex-column align-items-baseline">
          <img src="../assets/images/avipages_logo.svg" height="26" width="132" class="footer_aviapages_logo" alt="aviapages logo">
          <p class="description"><a href="https://aviapages.com" class="footer_nav_link">Aviapages</a> lets you charter, buy or sell private jets, book business aviation related services: FBOs, dealers, brokers.
            Buy or sell aircraft, order flight support, handling or catering.</p>
          <p class="description">
            By choosing <a href="https://avipages.com" class="footer_nav_link">Aviapages</a>, you can be sure of a great choice of business jets, private helicopters, FBO’s, brokers, manufacturers and other information you need on a daily basis.
            We offer more than 10000 aircraft for booking even in the most distant and exotic destinations.
          </p>
        </div>

      </div>

      <div class="social_links__bottom row">
        <div class="col-12 d-flex d-md-none flex-column align-items-center">
          <a class="fs24" href="tel:+31 970 102 80 860">+31 970 102 80 860</a>
          <a class="fs24" href="mailto:sales@charterapi.com">sales@charterapi.com</a>
        </div>

        <div class="col-12 d-md-none">
          <div class="social_links justify-content-center">
            <a href="https://www.facebook.com/aviapages" class="social_link"><img height="44" width="44" src="../assets/images/facebook_icon.webp" alt="facebook icon"></a>
            <a href="https://www.linkedin.com/company/aviapages-com" class="social_link"><img height="44" width="44" src="../assets/images/linkedin_icon.webp" alt="linkedin icon"></a>
            <a href="https://www.youtube.com/channel/UCvlVm4usoe_WCa87SeiSBzg" class="social_link"><img height="44" width="44" src="../assets/images/youtube_icon.webp" alt="youtube icon"></a>
            <a href="https://twitter.com/aviapagescom" class="social_link"><img height="44" width="44" src="../assets/images/twitter_icon.webp" alt="twitter icon"></a>
            <a href="https://twitter.com/aviapagescom" class="social_link"><img height="44" width="44" src="../assets/images/instagram_icon.webp" alt="instagram icon"></a>
            <a href="https://telegram.me/aviapages" class="social_link"><img height="44" width="44" src="../assets/images/telegram_icon.webp" alt="telegram icon"></a>
          </div>
        </div>
      </div>

      <div class="row copyright_section">
        <div class="col-12 col-md-6 copyright">CharterAPI {{new Date().getFullYear()}}</div>
        <div class="col-12 col-md-6 d-flex privacy_links">
          <a href="https://aviapages.com/privacy_policy/" class="footer_nav_link">Privacy Policy</a>
          <div class="vertical_devider"></div>
          <a href="https://aviapages.com/terms_and_conditions/" class="footer_nav_link">Legal Disclaimer</a>
        </div>

      </div>

    </div>

  </footer>

</template>

<script>
import axios from "axios";

export default {
  name: "FooterComponent",
  data(){
    return{
      subscription_email:"",
      subscription_email_notification:"",
      sending_email_in_progress:false
    }
  },

  methods:{
    sendMessage() {

      if (this.validateEmail() && !this.sending_email_in_progress) {
        this.sending_email_in_progress = true;
        axios.post(process.env.VUE_APP_BACKEND_URL + "send_message", {
          "email": this.subscription_email,
          "message": `${this.subscription_email} just subscribed to CharterAPI newsletter!`,
          "subject": `CharterAPI: new newsletter subscriber`
        }).then(
            response => {
              if (response.data._status_code == 202) {
                this.subscription_email_notification = "Subscribe successfully"
                this.subscription_email = ""
              }
              this.sending_email_in_progress = false;

            }).catch(error => {
          this.sending_email_in_progress = false;
          console.log(error);
          this.subscription_email_notification = 'Sending error';
        })

      }
    },
    validateEmail(){
      if(this.subscription_email != null){
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(!re.test(this.subscription_email)){
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.subscription_email_notification = "Incorrect email format";

        }
        if(this.subscription_email.length == 0){
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.subscription_email_notification = "Email field is required";

        }
        return re.test(this.subscription_email);
      }
      else return true;
    },


  },
  watch:{
    subscription_email(value){
      if(value){
         this.subscription_email_notification = "";
      }

    }
  }
}
</script>

<style scoped lang="scss">
footer{
  background-color: $dark-section-background-color;
  padding: 50px 0 40px;
  color: $white-color;
}

.logo_container{
  margin-bottom: 3.75rem;
}

.social_links{
  display: flex;
  justify-content: flex-end;

  .social_link{
    height: 44px;
    width: 44px;
    //border-radius: 50%;
    //background-color: $link-light-color;
    display: inline-block;
    text-align: center;
    line-height: 100%;
    &:not(:first-child){
      margin-left: 10px;
    }
    img{
      max-height: 100%;
    }
  }
  @include media-breakpoint-down(md){
     margin-top: 2.1875rem;

  }
  &__bottom{
    margin-top: 3.75rem;
  }
}

.footer_aviapages_logo{
  margin-top: 4px;
  max-height: 25px;
  margin-bottom: 1.25rem;
}
.fs24{
  font-size: 1.5rem;
}
.api_heading{
  margin-bottom: 0.9375rem;
  color: white;
}
.navigation_links{
   @include media-breakpoint-down(md) {
     display: flex;
     justify-content: center;
   }

  &__wrapper{
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(md) {
      margin-top: 3.75rem;
      //align-items: center;
      display: inline-flex;
      margin-left: auto;
      margin-right: auto;
    }

  }

  .footer_nav_link{
    margin-bottom: 0.9375rem;
  }
}
.footer_nav_link{
  color: $link-light-color;
}
.description__wrapper{
  @include media-breakpoint-down(md){
    margin-top: 3.75rem;
  }
}
.description{
  font-size: 0.875rem;
  line-height: 1.4375;
}
.signup_newsletter{
  margin-top: 3.75rem;
  &__label{
    color: white;
    font-size: 1.125rem;
    font-weight: normal;
    margin-bottom: 0;
  }
  @include media-breakpoint-down(md){
    margin-top: 2rem;
  }
  &__wrapper{
    position: relative;
    margin-top: 0.675rem;
    display: inline-block;
    @include media-breakpoint-down(md){
      width: 100%;
    }
  }
  &__input{
    border-radius: 2px;
    background-color: $third-color;
    height: 2.125rem;
    padding: 0 0.625rem;
    border: none;
    outline: none;
    color: white;
    &::placeholder{
      color: $blue-6-color;
    }
    width: 255px;
    @include media-breakpoint-down(md){
      width: 100%;
    }
  }
  &__submit_button{
    position: absolute;
    top: 50%;
    transform:translateY(-50%);
    right: 0.625rem;
    background: transparent;
    border: none;
    &:hover{
      margin-top: -2px;
    }

  }
  &__notification{
    position: absolute;
    bottom: -100%;
    left: 10px;
    right: 0;
    width: 100%;
  }
}
.copyright_section{

  @include media-breakpoint-down(md){
    margin-top: 3.75rem;
    display: flex;
    flex-direction: column-reverse;

    .copyright{
      margin-top: 2.1875rem;
      text-align: center;
    }
  }

}
.vertical_devider{
  background-color:$third-color;
  width: 1px;
  height: 24px;
}
.privacy_links{
  justify-content: flex-end;
  gap: 1.5625rem;
  @include media-breakpoint-down(md){
    justify-content: center;
  }
}


</style>