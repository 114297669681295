import { createRouter, createWebHistory } from 'vue-router'
// import landingView from "@/views/LandingView";

const routes = [
  {
   path: "/",
   name: 'Landing',
    component: () => import(/* webpackChunkName: "about" */ '../views/LandingView.vue'),
    meta: {
      title: 'Charter Aviation API',
      metaTags: [
        {
          name: 'description',
          content: 'API for Business Aviation. Get essential charter data for your system: calculate flight time, quote charter, ' +
              ' search airports or aircraft, get NOTAM or METAR information.'
        },
        {
          property: 'og:description',
          content: 'API for Business Aviation. Get essential charter data for your system: calculate flight time, quote charter, ' +
              ' search airports or aircraft, get NOTAM or METAR information.'
        },
        {
          property: 'og:title',
          content: 'Charter Aviation API'
        }
      ]
    }
  },
  {
    path: '/quote',
    name: 'LandingQuote',
    component: () => import(/* webpackChunkName: "about" */ '../views/LandingViewWithQuote.vue'),
    meta: {
      title: 'Charter Aviation API',
      metaTags: [
        {
          name: 'description',
          content: 'API for Business Aviation. Get essential charter data for your system: calculate flight time, quote charter, ' +
              ' search airports or aircraft, get NOTAM or METAR information.'
        },
        {
          property: 'og:description',
          content: 'API for Business Aviation. Get essential charter data for your system: calculate flight time, quote charter, ' +
              ' search airports or aircraft, get NOTAM or METAR information.'
        },
        {
          property: 'og:title',
          content: 'Charter Aviation API'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: 'Charter API - About',
      metaTags: [
        {
          name: 'description',
          content: 'API for Business Aviation. Get essential charter data for your system: calculate flight time, quote charter, ' +
              ' search airports or aircraft, get NOTAM or METAR information.'
        },
        {
          property: 'og:description',
          content: 'API for Business Aviation. Get essential charter data for your system: calculate flight time, quote charter, ' +
              ' search airports or aircraft, get NOTAM or METAR information.'
        },
        {
          property: 'og:title',
          content: 'Charter Aviation API - About'
        }
      ]
    }
  },

  {
    path: '/documentation',
    name: 'documentation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DocumentationView.vue'),
    meta: {
      title: 'Charter API - Documentation',
      metaTags: [
        {
          name: 'description',
          content: 'API for Business Aviation. Get essential charter data for your system: calculate flight time, quote charter, ' +
              ' search airports or aircraft, get NOTAM or METAR information.'
        },
        {
          property: 'og:description',
          content: 'API for Business Aviation. Get essential charter data for your system: calculate flight time, quote charter, ' +
              ' search airports or aircraft, get NOTAM or METAR information.'
        },
        {
          property: 'og:title',
          content: 'Charter Aviation API - Documentation'
        }
      ]
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/ContactUsView.vue'),
    meta: {
      title: 'Charter API - Contacts',
      metaTags: [
        {
          name: 'description',
          content: 'API for Business Aviation. Get essential charter data for your system: calculate flight time, quote charter, ' +
              ' search airports or aircraft, get NOTAM or METAR information.'
        },
        {
          property: 'og:description',
          content: 'API for Business Aviation. Get essential charter data for your system: calculate flight time, quote charter, ' +
              ' search airports or aircraft, get NOTAM or METAR information.'
        },
        {
          property: 'og:title',
          content: 'Charter Aviation API - Contacts'
        }
      ]
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('../views/PricingView.vue'),
    meta: {
      title: 'Charter API - Pricing',
      metaTags: [
        {
          name: 'description',
          content: 'API for Business Aviation. Get essential charter data for your system: calculate flight time, quote charter, ' +
              ' search airports or aircraft, get NOTAM or METAR information.'
        },
        {
          property: 'og:description',
          content: 'API for Business Aviation. Get essential charter data for your system: calculate flight time, quote charter, ' +
              ' search airports or aircraft, get NOTAM or METAR information.'
        },
        {
          property: 'og:title',
          content: 'Charter Aviation API - Pricing'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
      // Add the meta tags to the document head.
      .forEach(tag => document.head.appendChild(tag));

  next();
});



export default router
